import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class ActiveNavService {
    public activeNav     : any;
    private activeNavS   : BehaviorSubject<any> = new BehaviorSubject<any>(this.activeNav);
    activeNavS$          : Observable<any> = this.activeNavS.asObservable();

    constructor() {
        this.activeNav = 'home';
        this.activeNavS.next(this.activeNav);
    }

    getActiveNav() {
      return this.activeNav;
    }

    updateActiveNav(activeNav: any) {
      this.activeNav = activeNav;
      this.activeNavS.next(this.activeNav);
    }
}
