import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })

export class DashboardMenus {

  private menuesArray   : any  = []

  setMenu(menu){
    this.menuesArray = menu;
  }
  getMenu(){
    return this.menuesArray;
  }

}
