import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class EmailBucketService {
    public properties     : any = [];
    private propertiesS   : BehaviorSubject<any> = new BehaviorSubject<any>(this.properties);
    propertiesS$          : Observable<any> = this.propertiesS.asObservable();

    constructor() {
      if (typeof localStorage != 'undefined') {
        this.properties = JSON.parse(localStorage.getItem('BucketProps'));

        if(this.properties == null)
          this.properties = [];

        this.propertiesS.next(this.properties);
      }
    }

    getBucketProps() {
      return this.properties;
    }

    // getBucketProps_array() {
    //   let temp_arr = [];
    //   for (let key in this.properties) {
    //       temp_arr.push(this.properties[key]);
    //   }
    //   return temp_arr;
    // }

    getBucketProps_Count() {
      // var keys = Object.keys(this.properties);
      // return keys.length;
      return this.properties.length;
    }

    addBucketProps(bucketProp: any) {
      if (typeof bucketProp.id == "undefined") {
        bucketProp.id = bucketProp.p_id;
      }

      if (typeof bucketProp.p_id == "undefined") {
        bucketProp.p_id = bucketProp.id;
      }

      // this.properties[bucketProp.id] = bucketProp;
      this.properties.unshift(bucketProp);
      this.propertiesS.next(this.properties);

      console.log("this.properties: ",this.properties);

      localStorage.setItem('BucketProps', JSON.stringify(this.properties));
    }

    checkBucketPropsByID(propID: any) {
      for (let i = 0; i < this.properties.length; i++) {
          if(this.properties[i].id == propID) {
            return true;
          }
      }

      return false;

      // if(this.properties[index])
      //   return true;
      // else
      //   return false;
    }

    removeBucketPropByID(propID: any) {
      for (let i = 0; i < this.properties.length; i++) {
          if(this.properties[i].id == propID) {
            this.properties.splice(i, 1);
            break;
          }
      }

      // delete this.properties[propID];
      this.propertiesS.next(this.properties);

      console.log("this.properties: ",this.properties);

      localStorage.setItem('BucketProps', JSON.stringify(this.properties));
    }

    removeAllBucketProps() {
      this.properties = [];
      this.propertiesS.next(this.properties);

      console.log("this.properties: ",this.properties);

      localStorage.setItem('BucketProps', JSON.stringify(this.properties));
    }
}
