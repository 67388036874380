import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class ProjectFiterService {
    public projFilter     : any = { commercial: '', housing: ''};
    private projFilterS   : BehaviorSubject<any> = new BehaviorSubject<any>(this.projFilter);
    projFilterS$          : Observable<any> = this.projFilterS.asObservable();

    constructor() {
        this.projFilterS.next(this.projFilter);
    }

    getActiveProject() {
      return this.projFilter;
    }

    updateActiveProj(projFil: any) {
      this.projFilter = projFil;
      this.projFilterS.next(this.projFilter);
    }
}
