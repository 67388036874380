import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HelperService } from './helperFns.service';
import { AppSettings } from './app.setting';

@Injectable({ providedIn: 'root' })

export class FacebookLoginService {
    public auth2: any;

    constructor(
      private helperService : HelperService
    ) {
    }

    fbLibrary() {
      return new Promise(async (resolve) => {
        let self = this;
        if(typeof window != "undefined") {
          if(self.auth2)
            resolve(window['FB']);

          (window as any).fbAsyncInit = function() {
            window['FB'].init({
              appId      : AppSettings.FACBOOK_APP_KEY,
              cookie     : true,
              xfbml      : true,
              version    : 'v3.1'
            });
            window['FB'].AppEvents.logPageView();

            self.auth2 = window['FB'];
            resolve(window['FB']);
          };

          (function(d, s, id) {
             var js, fjs = d.getElementsByTagName(s)[0];
             if (d.getElementById(id)) {return;}
             js = d.createElement(s);
             js.id = id;
             js.async = true;
             js.defer = true;
             js.src = "https://connect.facebook.net/en_US/sdk.js";
             fjs.parentNode.insertBefore(js, fjs);
           }(document, 'script', 'facebook-jssdk'));
        }
      });
    }

    getFb_GB_Likes() {
        return new Promise(async (resolve) => {
          resolve({fan_count: 96988, id: "108557287188283"});

          let self = this;
          this.auth2.api(
            '/108557287188283/',
            'GET',
            {"fields" : "fan_count", "access_token": "EAAI6BNj7jUgBALZBhRwLZB0SbdywBEr3BuEeZAZBAhJwlimDZAwg3ZBpZBMZBUzAdcZAcZBm7Mn5VFh9eu1dGyEqqnkkkbH46hoReq07ELcqMEZAHjZASN2qIauN1OrKspoKZAMlaOtZC4l78VM46MuJYsdZCyCMBAL4IsG4kSY0UWcni3VqFRgnjAwjEzDqHtXbfGGSIH9OZAA5ZCV8kmjyvbvCuX1cI"},
            function(response) {
              console.log("--- FB Likes response:: ", response);
              if(!response.error)
                resolve(response);
              // else
              //   resolve({fan_count: 96988, id: "108557287188283"});
            }
          );
        });
    }

    login() {
        return new Promise(async (resolve) => {
          let self = this;
          this.auth2.login((response) => {
              if (response.authResponse) {

                self.auth2.api('/me', {
                  fields: 'id, email, first_name, last_name, picture'
                }, (userInfo) => {
                  let user = {
                                firstName : userInfo.first_name,
                                lastName  : userInfo.last_name,
                                email     : userInfo.email,
                                photoUrl  : userInfo.picture.data.url,
                                id        : userInfo.id,
                                authToken : response.authResponse.accessToken,
                                provider  : 'FACEBOOK'
                             }

                  resolve(user);
                });

              } else {
                console.log('User login failed');
              }
          }, {scope: 'public_profile, email'});
        });
    }

    logout() {
      if(typeof this.auth2 != 'undefined') {
        this.auth2.logout(function(response) {
          // Person is now logged out
          console.log('FB User logout: ', response);
        });
      }
    }
}
