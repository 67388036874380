import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HelperService } from './helperFns.service';

@Injectable({ providedIn: 'root' })

export class LastSaveSearchService {
    public searches     : any;
    public searchesURL  : any = "";
    private searchesS   : BehaviorSubject<any> = new BehaviorSubject<any>(this.searches);
    searchesS$          : Observable<any> = this.searchesS.asObservable();

    constructor(
      private helperService : HelperService
    ) {
      if (typeof localStorage != 'undefined') {
        this.searches = JSON.parse(localStorage.getItem('userLastSearch'));
        if(this.searches == null)
          this.searches = {};

        this.searchesS.next(this.searches);
      }
    }


    getSearch() {
      return this.searches;
    }

    getlastSearch_array() {
      let temp_arr = [];
      for (let key in this.searches) {
          temp_arr.push(this.searches[key]);
      }
      return temp_arr;
    }

    updateSearch(data: any) {
      // Update with new searches
      this.searches[data.route] = data;

      if(Object.keys(this.searches).length > 6) {
        let key = Object.keys(this.searches)[0];
        delete this.searches[key];
      }

      this.searchesS.next(this.searches);

      // Set to local storage
      if (typeof localStorage != 'undefined')
        localStorage.setItem('userLastSearch', JSON.stringify(this.searches));
    }
}
