import { Injectable } from '@angular/core';
import { AppSettings } from './app.setting';

@Injectable({ providedIn: 'root' })

export class InjectExternalLibraries {
    public googleMaps    : any;
    public googleCaptcha : any;

    constructor() {
    }

    injectGoogleMaps() {
      return new Promise(async (resolve) => {
        let self = this;
        if(typeof window != "undefined") {
          if(this.googleMaps)
            resolve(this.googleMaps);

          // Onload Function
          window['googleMapsLoaded'] = () => {
            this.googleMaps = window['google'];
            resolve(this.googleMaps);
          }

          (function(d, s, id) {
             var js, fjs = d.getElementsByTagName(s)[0];
             if (d.getElementById(id)) {return;}
             js = d.createElement(s);
             js.id = id;
             js.async = true;
             js.defer = true;
             js.src = "https://maps.googleapis.com/maps/api/js?key="+AppSettings.GOOGLE_MAPS_KEY+"&libraries=geometry,places&callback=googleMapsLoaded";
             fjs.parentNode.insertBefore(js, fjs);
           } (document, 'script', 'gmaps-jssdk'));
        }
      });
    }

    injectGoogleCaptcha() {
      return new Promise(async (resolve) => {
        let self = this;
        if(typeof window != "undefined") {
          if(this.googleCaptcha)
            resolve(this.googleCaptcha);

          // Onload Function
          window['googleCaptchaLoaded'] = () => {
            this.googleCaptcha = window['grecaptcha'];
            resolve(this.googleCaptcha);
          }

          (function(d, s, id) {
             var js, fjs = d.getElementsByTagName(s)[0];
             if (d.getElementById(id)) {return;}
             js = d.createElement(s);
             js.id = id;
             js.async = true;
             js.defer = true;
             js.src = "https://www.google.com/recaptcha/api.js?onload=googleCaptchaLoaded";
             fjs.parentNode.insertBefore(js, fjs);
           } (document, 'script', 'recaptcha-jssdk'));
        }
      });
    }
}
