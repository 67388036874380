import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
// import { DOCUMENT, APP_BASE_HREF } from '@angular/common';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { TransferHttpCacheModule } from '@nguniversal/common';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserStateInterceptor } from './browserstate.interceptor';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';

// import { FooterComponent } from './Site/includes/footer/footer.component';
// import { HeaderComponent } from './Site/includes/header/header.component';
// Site Includes
import { SiteIncludesModule } from './Site/includes/site-includes.module';
// import { PanelNavbarComponent } from './UserPanel/includes/panel-navbar/panel-navbar.component';
import { NgxSliderModule } from "@angular-slider/ngx-slider";

// Import Layouts
// import { UserDashboardLayoutComponent } from './Layouts/user-dashboard-layout/user-dashboard-layout.component';
// import { UserLayoutComponent } from './Layouts/user-layout/user-layout.component';

// Import Pages
// import { HomeComponent } from './Site/pages/home/home.component';

// // Modal Components
// import { LoginComponent } from './Site/entry-components/login/login.component';
// import { SiginUpOptsComponent } from './Site/entry-components/sigin-up-opts/sigin-up-opts.component';
// import { SiginUpComponent } from './Site/entry-components/sigin-up/sigin-up.component';
// import { ContactDetailsComponent } from './Site/entry-components/contact-details/contact-details.component';
// import { ProContactDetailsComponent } from './Site/entry-components/pro-contact-details/pro-contact-details.component';
// import { InquiryModalComponent } from './Site/entry-components/inquiry-modal/inquiry-modal.component';

// // Bootsteap Components
// import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
// import { TooltipModule } from 'ngx-bootstrap/tooltip';
// import { ModalModule } from 'ngx-bootstrap/modal';
// import { PopoverModule } from 'ngx-bootstrap/popover';

// // Plugins
// import { NgSelectModule } from '@ng-select/ng-select';
// import { DeferLoadModule } from '@trademe/ng-defer-load';

// Custom Pipes
// import { ApplicationPipesModule } from './services/pipes/application-pipes.module';

@NgModule({
  declarations: [
    AppComponent,
    // LoginComponent,
    // SiginUpOptsComponent,
    // SiginUpComponent,
    // ContactDetailsComponent,
    // ProContactDetailsComponent,
    // InquiryModalComponent,

    // Pages
    // HomeComponent
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    // FormsModule,
    // ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    // TransferHttpCacheModule,
    AppRoutingModule,
    // NgbModule,
    // SocialLoginModule,

    // Site Includes
    SiteIncludesModule,

    // // Custom Pipes Modules
    // ApplicationPipesModule,

    // // Bootsteap Components
    // ModalModule.forRoot(),

    // // Bootsteap Components and Plugins
    // NgSelectModule,
    // DeferLoadModule,
    NgxSliderModule
  ],
  providers: [
    // {
    //   provide: AuthServiceConfig,
    //   useFactory: provideConfig
    // },
    // { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true,
    },
    DatePipe,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    // LoginComponent,
    // SiginUpOptsComponent,
    // SiginUpComponent,
    // ContactDetailsComponent,
    // ProContactDetailsComponent,
    // InquiryModalComponent
  ]
})
export class AppModule { }
