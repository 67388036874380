import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class PurposeService {
    public purpose     : any;
    private purposeS   : BehaviorSubject<any> = new BehaviorSubject<any>(this.purpose);
    purposeS$          : Observable<any> = this.purposeS.asObservable();

    constructor() {
        this.purpose = 'sale';
        this.purposeS.next(this.purpose);
    }

    getPurpose() {
      return this.purpose;
    }

    updatePurpose(purpose: any) {
      this.purpose = purpose;
      this.purposeS.next(this.purpose);
    }
}
