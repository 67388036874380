import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { TawkToComponent } from './tawk-to/tawk-to.component';
import { MessengerComponent } from './messenger/messenger.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// // Enrty Components
// import { EntryComponentsModule } from '../entry-components/entry-components.module';

// Bootsteap Components
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    // EntryComponentsModule

    // Bootsteap Components
    ModalModule.forRoot(),
  ],
  declarations: [
    FooterComponent,
    HeaderComponent,
    TawkToComponent,
    MessengerComponent
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    TawkToComponent,
    MessengerComponent
  ]
})
export class SiteIncludesModule { }
