import { Component, OnInit, HostListener } from '@angular/core';
import { AppSettings, HelperService, ProjectFiterService } from '../../../services/_services';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public buildingImageFooter : any = true;
  public addIndex : any = true;
  public togglefoot : any = false;
  public paddingFooter : any = false;
  public btntext : any = '<i class="fa fa-info-circle"></i>  About, Contact & More';
  public activeRoute      : any = "";
  public topBtn           :any = false;
  public scrollBtnPg      :any = false;
  public showFooter      :any = true;
  constructor(
    private projFilterService         : ProjectFiterService,
    private helperService: HelperService,
    private router: Router,
  ) {
    this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {

        this.activeRoute = val.url;

        if(val.url == "/find-pro" ){
          this.addIndex = false;
          this.scrollBtnPg = false;
        }
        else if(val.url == "/"){
          this.scrollBtnPg = false;
          this.showFooter  = true;
        }
        else if(val.url == "/blue-world-city"){
          this.paddingFooter = true;
          this.scrollBtnPg = true;
        }
        else if(val.url == "/blue-world-city/overseas-block"){
          this.paddingFooter = true;
          this.scrollBtnPg = true;
        }
        else if(val.url == "/capital-smart-city"){
          this.paddingFooter = true;
          this.scrollBtnPg = true;
        }
        else if(val.url == "/skyparkone"){
          this.paddingFooter = true;
          this.scrollBtnPg = true;
          this.showFooter = false;
        }
        else if(val.url.indexOf("/dashboard") != -1 ){
          this.showFooter = false;
        }
        else if(val.url == "/skyparkone/property-for-sale" || val.url == "/6th-road-center/property-for-sale"){
          this.showFooter = false;
        }
        else if(val.url == "/invest/bookcall"){
          this.showFooter = false;
        }
        else if(val.url.indexOf("/agent") != -1) {
          this.paddingFooter = true;
          this.scrollBtnPg = true;
        }
        else if(val.url.indexOf("/interior-designer") != -1) {
          this.paddingFooter = true;
          this.scrollBtnPg = true;
        }
        else if(val.url.indexOf("/architect") != -1) {
          this.paddingFooter = true;
          this.scrollBtnPg = true;
        }
        else if(val.url.indexOf("/contractors-builder") != -1) {
          this.paddingFooter = true;
          this.scrollBtnPg = true;
        }
        else if(val.url.indexOf("/3d-animator") != -1) {
          this.paddingFooter = true;
          this.scrollBtnPg = true;
        }
        else if(val.url.indexOf("/home-insurance") != -1) {
          this.paddingFooter = true;
          this.scrollBtnPg = true;
        }
        else if(val.url.indexOf("/home-automation") != -1) {
          this.paddingFooter = true;
          this.scrollBtnPg = true;
        }
        else if(val.url.indexOf("/home-security") != -1) {
          this.paddingFooter = true;
          this.scrollBtnPg = true;
        }
        else if(val.url.indexOf("/property") != -1) {
          this.paddingFooter = true;
          this.scrollBtnPg = true;
        }
        else if(val.url.indexOf("/blog") != -1) {
          this.paddingFooter = false;
          this.scrollBtnPg = true;
        }
        else if(val.url.indexOf("/sale") != -1) {
          this.paddingFooter = true;
          this.scrollBtnPg = true;
        }
        else if(val.url.indexOf("/rent/") != -1 ||  val.url.indexOf("/skyparkone") != -1 ||  val.url.indexOf("/e-park") != -1 ||  val.url.indexOf("/6th-road-center") != -1 ||  val.url.indexOf("/new-sell") != -1 || val.url.indexOf("/we-buy") != -1 ||  (val.url.indexOf("/sell") != -1 && val.url != '/sell-with-gharbaar') || val.url.indexOf("/wanted") != -1 ||  val.url.indexOf("/agency/") != -1){
          this.showFooter = false;
        }
        else {
          this.addIndex = true;
          this.scrollBtnPg = false;
          this.showFooter = true;

        }

        if (typeof window != "undefined")
        window.scrollTo(0, 0)
      }
    });
  }

  @HostListener('window:scroll', ['$event']) track(event) {
    if (typeof window != "undefined") {
      if (window.pageYOffset >= 1800) {
        this.topBtn = true;
      }
      else{
        this.topBtn = false;
      }
    }
  }

  ngOnInit() {
  }
  ngAfterViewInit() {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if (typeof document != "undefined") {
      var imgDefer = document.getElementsByTagName('img');
      for (var i=0; i<imgDefer.length; i++) {
        if(imgDefer[i].getAttribute('data-src')) {
          imgDefer[i].setAttribute('src',imgDefer[i].getAttribute('data-src'));
        }
      }
    }
  }

  investRoute() {
    let routeLink =  this.helperService.investRoute(AppSettings.cities[0]);
    return routeLink;
  }

  togfoot() {
    if(this.togglefoot){
      this.togglefoot=false;
      this.btntext='<i class="fa fa-info-circle"></i>  About, Contact, & More';
    }
    else{
      this.togglefoot=true;
      this.btntext='<i class="fa fa-close"></i>  Close';
    }
  }
  setViewAllLocURL(city, cityId, purposeId, typeSubTypeId) {
    return'/locations/' + city + '-' + cityId + '-' + purposeId + '-' + typeSubTypeId;
  }
  setFilterProj(commercial, housing){
    this.projFilterService.updateActiveProj({commercial: commercial, housing: housing});
  }
  scrollTop(){
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

}
