import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class HideService {
    public hidden     : any = {};
    private hiddenS   : BehaviorSubject<any> = new BehaviorSubject<any>(this.hidden);
    hiddenS$          : Observable<any> = this.hiddenS.asObservable();

    constructor() {
      if (typeof localStorage != 'undefined') {
        this.hidden = JSON.parse(localStorage.getItem('hiddenProps'));
        if(this.hidden == null)
          this.hidden = {};

        this.hiddenS.next(this.hidden);
      }
    }

    getHiddenProps() {
      return this.hidden;
    }

    getHiddenProps_array() {
      let temp_arr = [];
      for (let key in this.hidden) {
          temp_arr.push(this.hidden[key]);
      }
      return temp_arr;
    }

    getHiddenProps_Count() {
      var keys = Object.keys(this.hidden);
      return keys.length;
    }

    addHiddenProp(hiddenProp: any) {
      if (typeof hiddenProp.id == "undefined") {
        hiddenProp.id = hiddenProp.p_id;
      }

      if (typeof hiddenProp.p_id == "undefined") {
        hiddenProp.p_id = hiddenProp.id;
      }

      this.hidden[hiddenProp.id] = hiddenProp;
      this.hiddenS.next(this.hidden);

      console.log("this.hidden: ",this.hidden);

      localStorage.setItem('hiddenProps', JSON.stringify(this.hidden));
    }

    getHiddenPropByIndex(index: any) {
      if(this.hidden[index])
        return true;
      else
        return false;
    }

    removeHiddenProp(index: any) {
      delete this.hidden[index];
      // this.hidden.splice(index, 1);
      this.hiddenS.next(this.hidden);

      console.log("this.hidden: ",this.hidden);

      localStorage.setItem('hiddenProps', JSON.stringify(this.hidden));
    }
}
